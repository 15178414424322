<template>
  <div>
    <b-col md="12" v-if="performanceIsBlocked">
      <b-alert variant="danger" show>
        <div class="alert-body">
          <span><strong>¡Bloqueado!</strong> Ya se encuentra bloqueado.</span>
        </div>
      </b-alert>
    </b-col>

    <b-col md="12">
      <div v-if="!performanceData && isEmpty">
        <b-alert variant="warning" show>
          <div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" />
            <span>Haga una busqueda mediante el filtro</span>
          </div>
        </b-alert>
      </div>
      <div v-if="!performanceData && !isEmpty">
        <b-alert variant="warning" show>
          <div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" />
            <span><strong>No hay registros. ¿Desea realizar el cálculo de rendimiento anual?</strong></span>
          </div>
        </b-alert>
      </div>
      <b-row class="mb-3">
        <b-col v-if="performanceData && !isEmpty">
          <b-row>
            <b-col md="4" sm="">
              <label>Número de registros</label>
              <v-select
                v-model="porPagina"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector"
                @input="changePerPage(porPagina)"
              />
            </b-col>
            <b-col md="" sm="6">
              <label>Buscar en tabla</label>
              <div class="d-flex">
                <b-form-input
                  class="mr-1"
                  placeholder="Buscar..."
                  type="search"
                  v-model="filter"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <div class="float-right mt-2 mr-2" v-if="performanceData && !isEmpty">
            <b-button
              variant="success"
              class="btn fives-club-excel-btn ml-1"
              @click="downloadExcel"
              :disabled="!performanceData || isLoadingExcel"
              ><feather-icon icon="FileTextIcon" /> Excel
              <b-spinner small label="Loading..." v-if="isLoadingExcel" />
            </b-button>
          </div>
          <div class="float-right mt-2" v-if="!performanceData && !isEmpty">
            <b-button
              :disabled="isLoadingExcel"
              variant="primary"
              v-b-tooltip.hover
              title="Calcular"
              @click="calculatePerformance"
              class="btn btn-success"
            >
              <b-spinner small v-if="isSavingPerformance" /> Calcular
            </b-button>
          </div>
          <div class="float-right mt-2" v-if="!performanceIsBlocked && performanceData">
            <b-button
							v-can="'fivesclub_profit_close_calculating_performance'"
              :disabled="isLoadingExcel"
              variant="primary"
              v-b-tooltip.hover
              title="Cerrar"
              @click="savePerformance"
              class="btn btn-danger"
            >
              <b-spinner small v-if="isSavingPerformance" /> Bloquear
            </b-button>
          </div>
          <div class="float-right mt-2 mr-2" v-if="!performanceIsBlocked && performanceData">
            <b-button
							v-can="'fivesclub_profit_allow_calculating_performance'"
              :disabled="isLoadingExcel"
              variant="primary"
              v-b-tooltip.hover
              title="Calcular"
              @click="recalculatePerformance"
              class="btn btn-success"
            >
              <b-spinner small v-if="isSavingPerformance" />Reprocesar
            </b-button>
          </div>
          <div class="float-right mt-2 mr-2"  v-if="!performanceIsBlocked">
            <b-button @click="openModalBonus" variant="primary">Bonos Masivos</b-button>
          </div>

          <b-modal
            v-model="modalVisible"
            title="Subir Archivo"
            hide-footer
            no-close-on-backdrop
          >
            <b-form @submit.prevent="uploadFile">
              <b-form-file
                v-model="selectedFile"
                placeholder="Adjunte el template de bonos..."
                drop-placeholder="Drop file here..."
                browse-text="🔎"
                :disabled="isUpdatingFile"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                class="mr-3"
              ></b-form-file>
              <hr>
              <b-button
                type="submit"
                :disabled="isUpdatingFile"
                class="btn btn-secondary float-right "
              >
                <span v-if="isUpdatingFile"><b-spinner small /> Cargando archivo </span>
                <span v-else>Subir Archivo</span>
              </b-button>
            </b-form>
          </b-modal>
        </b-col>
      </b-row>
    </b-col>

    <b-table
      v-if="performanceData"
      :items="performanceData"
      sticky-header="600px"
      responsive
      :fields="tableColumns"
      :tbody-tr-class="rowClass"
      primary-key="id"
      show-empty
      empty-text="Haga una busqueda mediante el filtro"
      class="position-relative mb-0"
      :busy.sync="isSavingPerformance"
      :filter="filter"
      :filter-included-fields="filterOn"
    >

      <template #cell()="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>
      <template #cell(adjustment)="data">
        <span v-if="performanceIsBlocked">{{data.value}}</span>
        <div class="" v-else>
          <input
            v-model="data.item.adjustment"
            @input="handleInputChange(data.item)"
            type="number"
            style="width: 8rem; margin-bottom: 2px;"
            class="form-control"
            :disabled="loadingPerformance == data.item.id"
            @keypress="onlyNumberWithDecimal"
          />
          <b-button v-if="data.item.showBtns" @click="updatePerformanceAdjustment(data.item)" variant="secondary" class="" size="sm" :disabled="loadingPerformance == data.item.id">
            <b-spinner v-if="loadingPerformance == data.item.id" small/> <feather-icon v-else icon="PlusCircleIcon" style="color: white" />
          </b-button>
          <b-button v-if="data.item.showBtns" @click="resetPerformanceAdjustment(data.item.id)" variant="primary" class="ml-1" size="sm" :disabled="loadingPerformance == data.item.id">
            <feather-icon icon="RefreshCwIcon" style="color: white" />
          </b-button>
        </div>
      </template>
      <template #empty="scope">
        <b-alert variant="warning" :show="true" class="mb-0">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            <span>{{ scope.emptyText }}</span>
          </div>
        </b-alert>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Cargando datos</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { utils } from "@/modules/fivesClub/mixins/utils"
import { toDecimal, showAlertMessage } from "@/helpers/helpers"
import vSelect from "vue-select";
export default {
  mixins: [utils],
  props: {
    performanceData: {
      type: Array,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    isSortDirDesc: {
      type: Boolean,
      required: true,
    },
    isLoadingCosts: {
      type: Boolean,
      required: false,
    },
    isEmpty: {
      type: Boolean,
      required: false,
    },
    isLoadingExcel: {
      type: Boolean,
      required: false,
    },
    perPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      modalVisible: false,
      selectedFile: null,
      isSortDir: this.isSortDirDesc,
      isSortBy: this.sortBy,
      isSavingPerformance: false,
      porPagina: this.perPage,
      filter: '',
      file: null,
      isUpdatingFile: false,
      loadingPerformance: false,
      filterOn: ['contractcode', 'ownercode', 'ownername', 'housingnumber'],
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("fivesClubProfit", ["performanceIsBlocked"]),
  },
  methods: {
    ...mapMutations('fivesClubProfit', ['setShowBtns','setIsShow','setUpdateAdjustment']),
    ...mapActions('fivesClubProfit', ['uploadPerformanceAdjustment','savePerformanceAdjustment','updatePerformanceAdjust']),

    handleInputChange(item) {
      this.setShowBtns(item)
    },
    resetPerformanceAdjustment(itemId) {
      this.setIsShow(itemId)
    },
    changePerPage(perPage) {
      this.$emit("change-per-page", perPage)
      this.porPagina = perPage
    },
    async savePerformance() {
      const isConfirmed = await this.confirmClos()
      if (isConfirmed) this.$emit("save-performance")
    },
    async calculatePerformance() {
      const isConfirmed = await this.confirmSaving()
      if (isConfirmed) this.$emit("calculate-performance")
    },
    async recalculatePerformance() {
      const isConfirmed = await this.confirmSaving()
      if (isConfirmed) this.$emit("recalculate-performance")
    },
    openModalBonus() {
      this.modalVisible = true;
    },
    closeAndResetModal() {
      this.modalVisible = false;
      this.selectedFile = null;
    },
    async uploadFile() {
      if (!this.selectedFile) {
        return showAlertMessage("Archivo sin adjuntar", "InfoIcon", `Favor de seleccionar un archivo, antes de guardar.`, "warning", 4000, "bottom-right")
      }
      this.isUpdatingFile = true
      const payload = { 'file': this.selectedFile }
      const response = await this.uploadPerformanceAdjustment(payload)
      if (response) {
        showAlertMessage('Éxito', 'InfoIcon', 'Los datos se exportaron correctamente.', 'success', 4000, 'bottom-right')
        if (this.performanceData?.length > 0) this.$emit('look-per-filter', {rentalPoolId: this.performanceData[0].rentalPool, year: this.performanceData[0].year})
      }
      this.closeAndResetModal();
      this.isUpdatingFile = false
    },
    async updatePerformanceAdjustment(item) {
      if (item.adjustment === "") {
        return showAlertMessage("Campo vacío", "InfoIcon", `Favor de ingresar un valor en el campo, antes de guardar..`, "warning", 4000, "bottom-right")
      }
      this.loadingPerformance = item.id
      const payload = {
        id: item.id,
        contractId: item.contractId,
        adjustmentId: item.adjustmentId,
        valueadjustments:item.adjustment,
        year: item.year,
        rentalPool: item.rentalPool
      }

      let result
      if (item.adjustmentId) {
        result = await this.updatePerformanceAdjust(payload)
      } else {
        result = await this.savePerformanceAdjustment(payload)
      }

      if (result?.status) 
      {
          result.data.forEach(element => {
          element.showBtns = false;
          element.valueOriginal = element.adjustment;
        });
        this.setUpdateAdjustment(result.data[0])
      }
      else this.setIsShow(item.id)

      this.loadingPerformance = false

    },
    async confirmClos() {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Está seguro?",
        text: "De bloquear el cálculo de rendimiento anual.",
        showDenyButton: true,
        confirmButtonText: "Sí, guardar",
        denyButtonText: "Cancelar",
      })
      return isConfirmed
    },
    async confirmSaving() {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Está seguro?",
        text: "De realizar el cálculo de rendimiento anual.",
        showDenyButton: true,
        confirmButtonText: "Sí, guardar",
        denyButtonText: "Cancelar",
        customClass: {
          confirmButton: 'swal-button-yes',
          denyButton: 'swal-button-cancel',
        },
      })
      return isConfirmed
    },
    formatDecimal(number) {
      return toDecimal(number)
    },
    async downloadExcel() {
      this.$emit("export-performance")
    },
    resetFilter() {
    this.$emit('look-per-filter');
  }
  },
}
</script>

<style lang="scss" >
.display-6 {
  font-size: 13px;
}
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 11px;
  }
  .buttonInfo button {
    padding: 9px;
  }
  .buttonInfo {
    display: table-cell;
  }
  .isCentered {
    align-content: center;
    align-items: center;
  }
  .isSpinner {
    padding: 2rem;
  }
}

  /* Estilo para el botón de confirmación */
  .swal-button-yes {
    background-color: #3085d6;
    color: #fff;
    margin-right: 10px;  /* Ajusta la separación a tu preferencia */
  }

  /* Estilo para el botón de cancelación */
  .swal-button-cancel {
    background-color: #d33;
    color: #fff;
  }
</style>
