<template>
  <div class="list-filters">
    <div><h4>Filtros</h4></div>

    <validation-observer v-slot="{ invalid }" ref="searchDetailTransactions">
      <b-form @submit.prevent="getProfit">
        <b-row  align-v="end">
          <!-- Tipo de Busqueda -->
          <b-col md="3">
            <validation-provider name="tipo Busqueda" rules="required">
              <b-form-group label="Pool de renta">
                <b-form-select v-model="rentalPool" placeholder="Pool">
                  <option selected value="">Seleccione un Pool de renta</option>
                  <option
                    v-for="condo in rentalPoolFilter"
                    :key="condo.id"
                    :value="condo.id"
                  >{{ condo.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2" lg="3">
            <b-form-group label="Año">
              <b-form-select v-model="year" :options="validYears" />
            </b-form-group>
          </b-col>
          <b-col md="2" v-if="showMonthFilter">
            <b-form-group label="Mes">
              <b-form-select v-model="month" :options="monthList" />
            </b-form-group>
          </b-col>
          <!-- Botones -->
          <b-col md="2" sm="6">
            <b-form-group label="">
              <div>
                <b-button
                  class="mr-1"
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="invalid || isLoadingProfit || isDowloadingExcel"
                > Buscar
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Ripple from "vue-ripple-directive";
import { utils } from "@/modules/fivesClub/mixins/utils";
import { monthList } from "@/data/data";

export default {
  directives: {
    Ripple,
  },
  mixins: [utils],
  props: {
    isLoadingProfit: {
      type: Boolean,
      required: false,
    },
    isDowloadingExcel: {
      type: Boolean,
      required: false,
    },
    showMonthFilter: {
      type: Boolean,
      default: true,
    },    
  },

  data() {
    return {
      porPagina: this.perPage,
      downExcel: true,
      rentalPool: "",
      monthList,
      datenow: new Date(),
      year: new Date().getFullYear(),
      month: 1,
    };
  },
  computed: {
    ...mapState("fivesClubProfit", ["rentalPoolFilter"]),
    validYears() {
        const years = [];
        const currentYear = new Date().getFullYear();
        const monthActual = new Date().getMonth();

        if (!this.showMonthFilter) {
          if (monthActual === 12) {
            years.push(currentYear);
          } else if (monthActual !== 12) {
            years.push(currentYear - 1);
          }
        } else {
          for (let i = currentYear - 2; i < currentYear + 1; i++) {
            years.push(i + 1);
          }
        }

        this.year = years.includes(this.year) ? this.year : years[0]
        return years;
    },
  },
  methods: {
    getProfit() {
      const payload = {
        rentalPoolId: this.rentalPool,
        year: this.year,
        month: this.month,
        isAnual:0
      }
      this.$emit("look-per-filter", payload)
    }
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}
.filters {
  padding-bottom: 2rem;
}
.paddler {
  padding-left: 1rem;
}

.filters {
  border: solid 1px #b8c2cc;
  padding: 12px 12px 13px 12px !important;
  margin: 0 0 16px 0;
}
</style>