// mixin.js
export const utils = {
  data () {
    return {
      phoneCodesAllowed: [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57],
      numberCodesAllowed: [ 48, 49, 50, 51, 52, 53, 54, 55, 56, 57], // keyCodes del 0 al 9 numeros enteros
      numbersCodeAndDecimalWithDot: [ 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 46, 110, 190], // 46 is dot, 110, 190
      numbersLettersCodesAllowed: [ 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
                  58, 59, 60, 61, 62, 63, 64, 65, 66, 67,
                  68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
                  78, 79, 80, 81, 82, 83, 84, 85, 86, 87,
                  88, 89, 90
                ], // keyCodes del 0 al 9 numeros enteros (48-57), 58-90 keycodes del abecedario a-z
    }
  },
  methods: {
    onlyNumber(event){
      const keyCode = ( event.keyCode ? event.keyCode : event.which)
      const respuesta = this.numberCodesAllowed.includes(keyCode)
      if ( !respuesta ) {
        event.preventDefault()
      }
    },
    onlyNumberWithDecimal(event){
      const keyCode = ( event.keyCode ? event.keyCode : event.which)
      const respuesta = this.numbersCodeAndDecimalWithDot.includes(keyCode)
      if ( !respuesta ) {
        event.preventDefault()
      }
    },
    //valida que se pueda ingresar números negativos, no se ingrese más de un caracter - 0 .
    validateNumberWithDecimal(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which
      const inputValue = String.fromCharCode(keyCode)
      const currentValue = event.target.value

      if (!/^[-0-9.]$/.test(inputValue)) {
        event.preventDefault()
      }

      const isNegativeSign = inputValue === '-' && currentValue.indexOf('-') === -1
      const isDecimalPoint = inputValue === '.' && currentValue.indexOf('.') === -1

      if (isNegativeSign && currentValue.length === 0 ) {
        return
      }

      const combinedInput = currentValue + inputValue;
      const hasDotDashCombination = /\.\-/.test(combinedInput)

      if (hasDotDashCombination) {
        event.preventDefault();
      }

      if ( isDecimalPoint && currentValue.indexOf('.') === -1 ) {
        return
      }

      if (isNegativeSign || isDecimalPoint) {
        return
      }

      if ( !/^-?\d*\.?\d*$/.test(currentValue + inputValue) ) {
        event.preventDefault()
      }
    },
    validarNumeroDecimal(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      const inputChar = String.fromCharCode(keyCode);
      // Expresión regular para validar números decimales
      const patronDecimal = /^-?\d+(\.\d+)?$/;

      // Verificar si el número cumple con el patrón
      const esDecimalValido = patronDecimal.test(inputChar);

      if ( !esDecimalValido) {
        event.preventDefault();
      }
    },
    onlyNumbersLetters(event){
      if (event.charCode!=0) {
        var regex = new RegExp("^[a-zA-Z0-9 ]*$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault()
        }
      }
    },
    formatPhoneNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which
      const respuesta = this.phoneCodesAllowed.includes(keyCode)
      if (!respuesta) {
        event.preventDefault()
      }
    },
    rowClass(item, type) {
      const colorClass = 'table-warning'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.status === false ) { return colorClass }
    },
  }
}

